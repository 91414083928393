import ApiService from "@/core/services/api.service";

export default {

    // -----API VERIFIKASI DATA CUTTING HE------
    getCuttingHEAll(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/verifikasi/data/cutting-he/all", { params: credentials })
    },
    getCuttingHESingle(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/verifikasi/data/cutting-he/edit", { params: credentials })
    },
    verifCuttingHE(credentials) {
        ApiService.setHeader();
        return ApiService.put("v2/verifikasi/data/cutting-he", credentials)
    },
}